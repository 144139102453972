<div class="overlaypopup">
    <div class="popup">
        <!-- <h3>{{message}}</h3>
        <div class="close">
            <div *ngIf="IsConfirmDiaglog">
                <input type="button" value="Yes" class="btn" (click)="hideidentity();">
            </div>
            <div *ngIf="!IsConfirmDiaglog">
                <input type="button" value="OK" class="btn" (click)="hideidentity();">
            </div>
            <div *ngIf="IsConfirmDiaglog">
                <input type="button" value="No" class="btn" (click)="hideidentitywithNo();">
            </div>
        </div> -->
        <div class="metadataheader border-bottom">
            <span> {{title}} </span>
        </div>

        <div class="metadatabody">
            <span> {{message}}</span>
        </div>
        <div class="row border-top">
            <div *ngIf="IsConfirmDiaglog" class="col-md-5" (click)="hideidentitywithNo();">
                <button class="btn button"> No </button>
            </div>
            <div *ngIf="!IsConfirmDiaglog" class="col-md-7"  (click)="hideidentity();">
                <button class="btn button"> OK </button>
            </div>
            <div *ngIf="IsConfirmDiaglog" class="col-md-5"  (click)="hideidentity();">
                <button class="btn button"> Yes </button>
            </div>
        </div>
    </div>

</div>