import { Component, Output, EventEmitter, Input, ComponentFactoryResolver, ComponentRef, ViewContainerRef, OnInit, ElementRef, ViewChild } from '@angular/core';


declare var ol: any;

@Component({
    selector: 'toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.css'],
})

export class ToastComponent implements OnInit {
    close = new EventEmitter();
    public toastMessage: string = '';

    constructor() {
    }

    ngOnInit() {
        var x = document.getElementById("toastDiv")
        x.className = "show";
        setTimeout(() => {
            x.className = x.className.replace("show", "");
            this.close.emit('event');
        }, 3000)
    }
}