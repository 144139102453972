<app-header></app-header>
<div class="banner-img web">
    <div class="banner-overlay"></div>
</div>
<div class="mobile">
    <img style="width: 100%;" src="assets/images/Mobile/MSG-Engineers-Gallery.jpg" />
</div>
<section id="about-us">
    <div class="containerX">
        <h1 class="pageTitle">Gallery</h1>
        <div *ngIf="services && services?.length > 0">
            <div class="galleryGroup">
                <div class="galleryBlock" (click)="openmodal(modal)" *ngFor="let item of services; let i = index">
                    <div class="galleryThumb" [style.background]="'url(assets/images/Gallery/' + item.Path + ')'">
                    </div>
                    <ng-template #modal let-d="close">
                        <div class="modal-header" style="padding: 20px 10px !important; padding-bottom: 10px !important">
                            <h5 style="margin-bottom: 0px !important; width: 100%;">
                                <span></span>
                                <i (click)="d('Cross click')" class="fas fa-times" style="float: right; font-size: 0.8rem !important;"></i>
                            </h5>
                        </div>
                        <div class="modal-body">
                            <img style="width: 100%;" [src]="'assets/images/Gallery/' + item.Path" />
                        </div>
                        <div class="modal-footer aboutModal likeModal">
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>