import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, NgModule, ViewContainerRef, ComponentFactoryResolver, ComponentRef, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../common/data.service';

@Component({
    selector: 'popupwindow',
    templateUrl: './popupwindow.component.html',
    styleUrls: ['./popupwindow.component.css']
})

export class PopUpWindowComponent {
    close = new EventEmitter();
    public message: string = 'Functionality is under Development';
    no = new EventEmitter();
    public IsConfirmDiaglog: boolean = false;
    public title: string = "";
    hideidentity() {
        this.close.emit('Yes');

    }
    hideidentitywithNo() {
        this.no.emit('No');
    }
}