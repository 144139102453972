<app-header></app-header>
<div class="home-banner-img web">
    <div class="home-banner-overlay"></div>
</div>
<div class="mobile">
    <img style="width: 100%;" src="assets/images/Mobile/MSG-Engineers-Home-Image.jpg" />
</div>
<section id="welcome">
    <div class="containerX">
        <h1 class="pageTitle">Welcome to M.S.G. Engineers</h1>
        <p>
            M.S.G. Engineers is a diversified “Agricultural Machinery parts, & CNC Machined Finish Component Solution” provider for Auto & Engineering component manufacturing and supply chain.
        </p>
        <p>
            Tapping into its proven capabilities, M.S.G. Engineers is ready to live up to its commitment to fulfill the rising indigenous demand and compete in the automotive sector.
        </p>
    </div>
</section>
<section id="quality-policy" class="colorBg">
    <div class="containerX">
        <h2 class="subTitle">Our Quality Policy</h2>
        <div class="row">
            <div class="col-12 col-lg-9 col-md-9" style="background-image: url(assets/images/QualityApproved.png); background-repeat: no-repeat; background-position: bottom right;">
                <blockquote class="slogan">"Leadership through Quality & Innovation"</blockquote>
                <p>We are providing Quality Products and Services up to the ultimate satisfaction of our customers & consumers.</p>
                <p>We at M.S.G. Engineers are committed to achieve this by:</p>
                <ul style="list-style-type: square;">
                    <li>Upgradation of technologies for Plants & Equipments</li>
                    <li>Implementing Latest Quality System</li>
                    <li>Waste Reduction Measures</li>
                    <li>Total Employees Involvement</li>
                </ul>
                <a style="color: #E5322C" [routerLink]="'/about-msg-engineers'">Read more about us here...</a>
            </div>
            <div class="col-12 col-lg-3 col-md-3">
                <img class="sideImg" src="assets/images/MSG-Engineers-ISO-9001-2015-Certificate.jpg" />
            </div>
        </div>
    </div>
</section>
<section id="our-clients">
    <div class="containerX">
        <h2 class="subTitle">Our Clients</h2>
        <div class="ourClientsArea">
            <div class="ourClientsCard">
                <img src="assets/images/Our-Clients/Sonalika-Agri-Logo-MSG-Engineers.jpg" />
            </div>
            <div class="ourClientsCard">
                <img src="assets/images/Our-Clients/Escorts-Logo-MSG-Engineers.jpg" />
            </div>
            <div class="ourClientsCard">
                <img src="assets/images/Our-Clients/Ace-Logo-MSG-Engineers.jpg" />
            </div>
            <div class="ourClientsCard">
                <img src="assets/images/Our-Clients/Talbros-Engineering-Logo-MSG-Engineers.jpg" />
            </div>
            <div class="ourClientsCard">
                <img src="assets/images/Our-Clients/Beri-Udyog-MSG-Engineers.jpg" />
            </div>
            <div class="ourClientsCard">
                <img src="assets/images/Our-Clients/Sietz-Logo-MSG-Engineers.jpg" />
            </div>
            <div class="ourClientsCard">
                <img src="assets/images/Our-Clients/Aanand-Tractor-Parts-Logo-MSG-Engineers.jpg" />
            </div>
            <div class="ourClientsCard">
                <img src="assets/images/Our-Clients/Maschio-Gaspardo-MSG-Engineers.jpg" />
            </div>
            <div class="ourClientsCard">
                <img src="assets/images/Our-Clients/Tecumseh-MSG-Engineers.jpg" />
            </div>
            <div class="ourClientsCard">
                <img src="assets/images/Our-Clients/Many-More-MSG-Engineers.jpg" />
            </div>
        </div>
    </div>
</section>
<section id="testimonials" class="colorBg">
    <div class="containerX">
        <h2 class="subTitle">What they say about us</h2>
        <app-testimonials></app-testimonials>
    </div>
</section>
<app-footer></app-footer>