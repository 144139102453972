import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { DataService } from './common/data.service';
import { ModalstateService } from './common/modalstate.service';
import { ShareService } from './common/share.service';
import { MessageService } from './common/message.service';
import { NgxSpinnerModule } from 'ngx-spinner'

import { AppRoutingModule } from './app-routing.module';
import { PopUpWindowComponent } from './popup/popupwindow.component';
import { ToastComponent } from './toast/toast.component';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutMsgEngineersComponent } from './about-msg-engineers/about-msg-engineers.component';
import { ContactComponent } from './contact/contact.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { GalleryComponent } from './gallery/gallery.component';
import { OurServicesFabricatedPartsComponent } from './our-services-fabricated-parts/our-services-fabricated-parts.component';
import { OurServicesRotavatorPartsComponent } from './our-services-rotavator-parts/our-services-rotavator-parts.component';
import { OurServicesForgingPartsComponent } from './our-services-forging-parts/our-services-forging-parts.component';
import { OurServicesVmcFinishedPartsComponent } from './our-services-vmc-finished-parts/our-services-vmc-finished-parts.component';
import { OurServicesAxelsComponent } from './our-services-axels/our-services-axels.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutMsgEngineersComponent,
    ContactComponent,
    TestimonialsComponent,
    OurServicesComponent,
    PopUpWindowComponent,
    ToastComponent,
    GalleryComponent,
    OurServicesFabricatedPartsComponent,
    OurServicesRotavatorPartsComponent,
    OurServicesForgingPartsComponent,
    OurServicesVmcFinishedPartsComponent,
    OurServicesAxelsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    NgxSpinnerModule,
    HttpClientModule,
  ],
  providers: [DataService, ShareService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
