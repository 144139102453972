import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-services-fabricated-parts',
  templateUrl: './our-services-fabricated-parts.component.html',
  styleUrls: ['../our-services/our-services.component.css']
})
export class OurServicesFabricatedPartsComponent implements OnInit {

  services = [
    {
      Group: "Fabricated Parts",
      Images: [
        {ID: 1, Path: "MSG-Engineers-Fabricated-Parts-1.jpg"},
        {ID: 2, Path: "MSG-Engineers-Fabricated-Parts-2.jpg"},
        {ID: 3, Path: "MSG-Engineers-Fabricated-Parts-3.jpg"},
        {ID: 4, Path: "MSG-Engineers-Fabricated-Parts-4.jpg"},
      ]
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
