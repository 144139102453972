<app-header></app-header>
<div class="banner-img web">
    <div class="banner-overlay"></div>
</div>
<div class="mobile">
    <img style="width: 100%;" src="assets/images/Mobile/MSG-Engineers-Our-Services.jpg" />
</div>
<section id="about-us">
    <div class="containerX">
        <h1 class="pageTitle">Our Services</h1>
        <div class="button-group">
            <button class="btn" routerLink="/our-services-cnc-finished-parts">CNC Finished Parts</button>
            <button class="btn" routerLink="/our-services-fabricated-parts">Fabricated Parts</button>
            <button class="btn" routerLink="/our-services-rotavator-parts">Rotavator Parts</button>
            <button class="btn" routerLink="/our-services-forging-parts">Forging Parts</button>
            <button class="btn active">VMC Finished Parts</button>
            <button class="btn" routerLink="/our-services-axels">Axels</button>
        </div>
        <hr>
        <div *ngIf="services && services?.length > 0">
            <ng-container *ngFor="let item of services; let i = index;">
                <div class="galleryGroup">
                    <div class="galleryBlock">
                        <div class="galleryThumb groupName">{{item.Group}}</div>
                    </div>
                    <div class="galleryBlock" *ngFor="let photo of item.Images; let j = index">
                        <div class="galleryThumb" [style.background]="'url(assets/images/Services/' + photo.Path + ')'">
                        </div>
                    </div>
                </div>
                <hr>
            </ng-container>
        </div>
    </div>
</section>
<app-footer></app-footer>