<footer class="footer">
    <div class="containerX">
        <h3 class="brandName">M.S.G. Engineers</h3>
        <div class="row">
            <div class="col-12 col-lg-8 col-md-8">
                <div class="address">
                    <div>Plot No. 2, Veer Industrial Complex, Sector-57, Near STL Global Ltd., Faridabad - 121004, Haryana, India</div>
                    <div>Contact No.: +91 98 18 480 648, +91 93 500 300 36, 0129 490 1447</div>
                    <div>Email ID: msg.engineers@gmail.com</div>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-4">
                <ul class="footer-menu">
                    Menu:
                    <li routerLink="/">Home</li>
                    <li routerLink="/about-msg-engineers">About Us</li>
                    <li routerLink="/our-services-cnc-finished-parts">Our Services</li>
                    <li routerLink="/gallery">Gallery</li>
                    <li routerLink="/contact-msg-engineers">Contact Us</li>
                </ul>
            </div>
            <div class="col-12 col-lg-12 col-md-12 credits">
                <hr> All rights reserved. &copy; M.S.G. Engineers. Designed & Developed by <a href="http://aumtechmantra.com" target="blank" rel="follow">Aum Techmantra LLP</a>
            </div>
        </div>
    </div>
</footer>