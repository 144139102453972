import { Injectable, Injector, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef, ComponentRef } from '@angular/core';
import { PopUpWindowComponent } from '../popup/popupwindow.component';
import {ToastComponent} from '../toast/toast.component';

@Injectable()
export class MessageService {
  //private componentRef: ComponentRef<{PopUpWindowComponent}>;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector) { }

  OpenMessage(message: string, IsConfirmDiaglog: boolean, title: string): ComponentRef<PopUpWindowComponent> {

    // Create a component reference from the component 
    let componentRef = this.componentFactoryResolver
      .resolveComponentFactory(PopUpWindowComponent)
      .create(this.injector);

    componentRef.instance.message = message;
    componentRef.instance.IsConfirmDiaglog = IsConfirmDiaglog;
    componentRef.instance.title = title;
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);
    return componentRef;

  }

  OpenToastMessage(message: string): ComponentRef<ToastComponent> {
    
        // Create a component reference from the component 
        let componentRef = this.componentFactoryResolver
          .resolveComponentFactory(ToastComponent)
          .create(this.injector);
        componentRef.instance.toastMessage = message;
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);
    
        componentRef.instance.close.subscribe(() => {
          componentRef.destroy();
      });
        // Get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
          .rootNodes[0] as HTMLElement;
    
        // Append DOM element to the body
        document.body.appendChild(domElem);
        return componentRef;
      }
      showMessage(msg:string){
        let dialogComponentRef = this.OpenToastMessage(msg);
        dialogComponentRef.instance.close.subscribe(() => {
            dialogComponentRef.destroy();
        });
    }

}
