<app-header></app-header>
<div class="banner-img web">
    <div class="banner-overlay"></div>
</div>
<div class="mobile">
    <img style="width: 100%;" src="assets/images/Mobile/About-MSG-Engineers.jpg" />
</div>
<section id="about-us">
    <div class="containerX">
        <h1 class="pageTitle">About M.S.G. Engineers</h1>
        <div class="row">
            <div class="col-12 col-lg-7 col-md-7">
                <p>Incorporated in the year 2012, at Faridabad, Haryana, India; We “M.S.G. ENGINEERS”, are the reputed Sole Proprietorship firm, engaged in Manufacturing and Supplying the finest quality range of Agricultural Machinery Parts, Auto Parts,
                    Hot Steel Forging, CNC Finish Parts, Handling Tool Room & General Engg. Works, Industrial Fabrication Service, etc.</p>
                <p>The provided assortment of products is highly demanded by our esteemed clients for its high strength, durability, corrosion resistance, perfect finish, sturdiness, etc. These products are well-manufactured by the experienced team of our
                    adept professionals using the finest quality raw material and advanced techniques.</p>
                <p>The offered products are available in various shapes and other related specifications as per the demands of our valued clients. We offer these products to our esteemed clients at the most competitive prices.</p>
            </div>
            <div class="col-12 col-lg-5 col-md-5">
                <img style="width: 100%;" src="assets/images/CEO-Khillan-Singh-MSG-Engineers-1.jpg" />
                <div class="imgCaption">Mr. Khillan Singh, CEO - M.S.G. Engineers</div>
            </div>
        </div>
    </div>
</section>
<section id="brief-profile" class="colorBg">
    <div class="containerX">
        <h2 class="subTitle">Our Brief Profile</h2>
        <div class="row">
            <div class="col-12 col-lg-7 col-md-7">
                <ul>
                    <li>Professionally-Managed Organization</li>
                    <li>Established in 2012 with an experience of a Decade</li>
                    <li>An ISO 9001:2015 Certified Organization</li>
                    <li>Pioneer Manufacturer of Agricultural Machinery Parts in North India</li>
                    <li>In-house Tool Designing & Manufacturing</li>
                    <li>Complete Solutions for:
                        <ul>
                            <li>Agricultural Machinery Parts</li>
                            <li>Auto Parts</li>
                            <li>Hot Steel Forging</li>
                            <li>Fabrication</li>
                            <li>CNC and VMC Finished Parts</li>
                            <li>New Product Development</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-lg-5 col-md-5">
                <img style="width: 100%;" src="assets/images/MSG-Engineers-Brief-Profile.jpg" />
            </div>
        </div>
    </div>
</section>

<section id="vision-mission">
    <div class="containerX">
        <h2 class="subTitle">Our Vision & Mission</h2>
        <div class="vissionMissionArea">
            <div class="vissionMissionBox">
                <img src="assets/images/vision.png" />
                <p>To Become a Leading Global supplier to Automotive & Non-Auto OEMs in our Product Category
                </p>
            </div>
            <div class="vissionMissionBox">
                <img src="assets/images/mission.png" />
                <p><span class="Caps">M</span>ainly focus on<br/><span class="Caps">S</span>atisfying customers and fulfill <br/><span class="Caps">G</span>oals
                </p>
            </div>
        </div>
    </div>
</section>
<section id="certifications" class="colorBg">
    <div class="containerX">
        <h2 class="subTitle">Our Certifications</h2>
        <div class="row">
            <div class="col-12 col-lg-6 col-md-6">
                <img style="width: 100%;" src="assets/images/MSG-Engineers-ISO-9001-2015-Certificate.jpg" />
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <img style="width: 100%;" src="assets/images/MSG-Engineers-SIDBI-India-MSE-Awards.jpg" />
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>