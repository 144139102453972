import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-services-axels',
  templateUrl: './our-services-axels.component.html',
  styleUrls: ['../our-services/our-services.component.css']
})
export class OurServicesAxelsComponent implements OnInit {

  services = [
    {
      Group: "Axels",
      Images: [
        {ID: 1, Path: "MSG-Engineers-Axels-1.jpg"},
        {ID: 2, Path: "MSG-Engineers-Axels-2.jpg"},
        {ID: 3, Path: "MSG-Engineers-Axels-3.jpg"},
        {ID: 4, Path: "MSG-Engineers-Axels-4.jpg"},
        {ID: 5, Path: "MSG-Engineers-Axels-5.jpg"},
      ]
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}