<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="cog" [fullScreen]="true">
    <p style="color: white"> M.S.G. Engineers </p>
</ngx-spinner>
<app-header></app-header>
<div class="banner-img web">
    <div class="banner-overlay"></div>
</div>
<div class="mobile">
    <img style="width: 100%;" src="assets/images/Mobile/Contact-MSG-Engineers.jpg" />
</div>
<section>
    <div class="containerX">
        <h1 class="pageTitle">Contact Us</h1>
        <p>Thank you for visiting M.S.G. Engineers. We hope that you must have read about us, if not then read about <a routerLink="/about-msg-engineers">M.S.G. Engineers here.</a></p>
        <p>We deal in manufacturing of Agricultural Machinery Parts, Auto Parts, Hot Steel Forging, CNC and VMC Finished Parts, you can view a brief of what we have manufactured for our clients here in <a routerLink="/our-services">Our Services</a> section.</p>
        <div class="row">
            <div class="col-12 col-lg-6 col-md-6">
                <p>We have worked for the biggest companies in the field of Agricultural Parts and Automotive Parts in India including but not limited to Sonalika, Escorts, ACE, Talbros Axels etc.</p>
                <hr>
                <p class="services">Factory</p>
                <p>Plot No. 2, Veer Industrial Complex<br />Sector-57, Near STL Global Ltd.<br /> Faridabad - 121004, Haryana<br /> INDIA
                </p>
                <hr>
                <p class="services">Contact No.</p>
                <p><a href="tel:+919818480648">+91 98 18 480 648</a>
                    <br/><a href="tel:+919350030036">+91 93 500 300 36</a>
                    <br/><a href="tel:+911294901447">0129 490 1447</a>
                </p>
                <p class="services">Email ID</p>
                <p><a href="mailto:msg.engineers@gmail.com">msg.engineers@gmail.com</a></p>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <form class="contactForm" #form="ngForm" (ngSubmit)="submit()">
                    <p class="services">Contact Us</p>
                    <hr>
                    <div class="form-group">
                        <label class="label">Name<span class="mandatory">*</span></label>
                        <input class="form-control" type="text" name="name" [(ngModel)]="name" #n="ngModel" pattern="[A-Z a-z]*" minlength="3" maxlength="50" required />
                        <div class="error-block" *ngIf="n.touched && n.errors?.required">Name is required</div>
                        <div class="error-block" *ngIf="name?.length > 0 && n.errors?.pattern">Enter valid name</div>
                        <div class="error-block" *ngIf="n.dirty && n.errors?.minlength">Enter valid name</div>
                    </div>
                    <div class="form-group">
                        <label class="label">Contact No.<span class="mandatory">*</span></label>
                        <input class="form-control" type="text" name="mobile" [(ngModel)]="mobile" #m="ngModel" pattern="\d*" minlength="10" maxlength="10" required />
                        <div class="error-block" *ngIf="m.touched && m.errors?.required">Contact No. is required</div>
                        <div class="error-block" *ngIf="mobile?.length > 0 && m.errors?.pattern">Enter valid Contact No.</div>
                        <div class="error-block" *ngIf="m.dirty && !m.errors?.pattern && m.errors?.minlength">Enter valid Contact No.</div>
                    </div>
                    <div class="form-group">
                        <label class="label">Email</label>
                        <input class="form-control" type="email" name="email" [(ngModel)]="email" #e="ngModel" maxlength="200" email />
                        <div class="error-block" *ngIf="email?.length > 0 && e.errors?.email">Enter valid Email ID</div>
                    </div>
                    <div class="form-group">
                        <label class="label">Interested In</label>
                        <select class="form-control" name="service" [(ngModel)]="service">
                            <option value="">Select</option>
                            <option value="Agricultural Parts">Agricultural Parts</option>
                            <option value="Auto Parts">Auto Parts</option>
                            <option value="Forging">Forging</option>
                            <option value="CNC Parts">CNC Parts</option>
                            <option value="VMC Parts">VMC Parts</option>
                            <option value="New Product Development">New Product Development</option>
                            <option value="Other Services">Other Services</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="label">Message</label>
                        <textarea class="form-control" rows="3" name="message" [(ngModel)]="message" maxlength="1000">
                        </textarea>
                        <div class="charCount">{{message?.length}}/1000</div>
                    </div>
                    <div class="error-block" *ngIf="form.dirty && form.invalid">Fill up form properly.</div>
                    <div class="form-group" style="text-align: center;">
                        <button class="btn btn-primary btn-contact" [disabled]="form.pristine || form.dirty && form.invalid">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>