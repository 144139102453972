import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  testimonials = [
    {Company: "Talbros Engineering", Matter: "<p>Working with MSG Engineers, We learned that “no” was not in their vocabulary.</p><p>No matter how challenging a part seemed to be, they went the extra mile to think out of the box in order to find ways to forge that product. Not only did they find a solution, but they did it in a timely manner.</p>"},
    {Company: "Sonalika Agriculture", Matter: "<p>We are very proud to have partnered exclusively with MSG Engineers.</p><p>The service we get is top notch, communications are open and honest, and the parts are of exceptional quality and deliver on time. Really couldn’t ask for a better supplier! Keep that bar set high my friends.</p>"},
    {Company: "Beri Udyog", Matter: "<p>Quality, on-time delivery, and competitive pricing.</p><p>MSG Engineers Company’s consistency meets (and exceeds) these desired requirements of a good supplier.</p><p>Additionally, MSG Engineers is innovative, and believes in partnering with their customers.</p>"},
    {Company: "Escorts", Matter: "<p>They offered us the highest quality products and services, meet all our agricultural machinery parts project and budget requirements, will order more in the future!</p>"},
    {Company: "ACE", Matter: "<p>MSG Engineers experienced workforce operates in a flexible framework offering us complete service coverage, quality products and fast delivery!</p>"},
    {Company: "Sietz", Matter: "<p>MSG Engineers is a leader in their industry, and a textbook example of dedication and commitment to their family, employees, customers, suppliers and community.</p>"},
    {Company: "Aanand Tractor Parts", Matter: "<p>MSG Engineers focus on quality and reliable manufacturing process, they are the Finishing and Fabrication specialists who can handle all our project need.</p>"},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
