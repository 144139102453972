import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutMsgEngineersComponent } from './about-msg-engineers/about-msg-engineers.component';
import { ContactComponent } from './contact/contact.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HomeComponent } from './home/home.component';
import { OurServicesAxelsComponent } from './our-services-axels/our-services-axels.component';
import { OurServicesFabricatedPartsComponent } from './our-services-fabricated-parts/our-services-fabricated-parts.component';
import { OurServicesForgingPartsComponent } from './our-services-forging-parts/our-services-forging-parts.component';
import { OurServicesRotavatorPartsComponent } from './our-services-rotavator-parts/our-services-rotavator-parts.component';
import { OurServicesVmcFinishedPartsComponent } from './our-services-vmc-finished-parts/our-services-vmc-finished-parts.component';
import { OurServicesComponent } from './our-services/our-services.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-msg-engineers', component: AboutMsgEngineersComponent },
  { path: 'our-services-cnc-finished-parts', component: OurServicesComponent },
  { path: 'our-services-fabricated-parts', component: OurServicesFabricatedPartsComponent },
  { path: 'our-services-rotavator-parts', component: OurServicesRotavatorPartsComponent },
  { path: 'our-services-forging-parts', component: OurServicesForgingPartsComponent },
  { path: 'our-services-vmc-finished-parts', component: OurServicesVmcFinishedPartsComponent },
  { path: 'our-services-axels', component: OurServicesAxelsComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'contact-msg-engineers', component: ContactComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    scrollOffset: [0, 0],
    anchorScrolling: "enabled",
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
