import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  services = [
    {ID: 1, Path: "MSG-Engineers-Gallery-1.jpg"},
    {ID: 2, Path: "MSG-Engineers-Gallery-2.jpg"},
    {ID: 3, Path: "MSG-Engineers-Gallery-3.jpg"},
    {ID: 4, Path: "MSG-Engineers-Gallery-4.jpg"},
    {ID: 5, Path: "MSG-Engineers-Gallery-5.jpg"},
  ]

modal: NgbModalRef;

constructor(private modalService: NgbModal,) { }

ngOnInit(): void {
}

openmodal(modal) {
this.modal = this.modalService.open(modal, { keyboard: false, centered: true, size: 'lg' });
}

}
