import { Component, OnInit } from '@angular/core';
import { DataService } from '../common/data.service';
import { MessageService } from '../common/message.service'
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner'
import { PopUpWindowComponent } from '../popup/popupwindow.component';
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  entryComponents: [PopUpWindowComponent]
})
export class ContactComponent implements OnInit {

  name: string;
  mobile: string;
  email: string;
  service: string = "";
  message: string = "";

  constructor(private ds: DataService, private spinner: NgxSpinnerService, private ms: MessageService, private http: HttpClient) { }

  ngOnInit(): void {
  }

  submit() {
    this.spinner.show();
    this.emailFormClient();
    this.emailFormTeam();
    this.ds.createService(environment.apiURL + "GenericSqlDB/PostExceProcTable",
      {
        ProcedureName: 'InsertContactData', SqlParamsArr: [
          { ParamName: '@Name', ParamValue: this.name },
          { ParamName: '@Contact', ParamValue: this.mobile },
          { ParamName: '@Email', ParamValue: this.email },
          { ParamName: '@Service', ParamValue: this.service },
          { ParamName: '@Message', ParamValue: this.message }]
      }).subscribe
      (data => {
        if (data) {

        }
        else {

        }
        this.ms.showMessage("Submitted Successfully");
        this.spinner.hide();
      });
  }

  emailFormClient() {
    let mail = {
      "owner_id": "89549865",
      "token": "EvbdReEqM5l6CzVPPgIC",
      "smtp_user_name": "smtp36636748",
      "message": {
        "template_id": "b609b2b69824fb41ef8c3602ab7e62ba",
        "subject": "Thank you for contacting M.S.G. ENGINEERS",
        "from_email": "donotreply@aumswow.com",
        "from_name": "M.S.G. ENGINEERS",
        "to": [
          {
            "email": this.email,
            "name": this.name,
            "type": "to"
          }
        ],
        "headers": {
          "Reply-To": "postmaster@aumswow.com",
          "X-Unique-Id": "id "
        },
        "dynamic_value": {
          "ClientName": this.name,
          "InterestedIn": this.service
        },
      }
    }
    this.http.post(environment.mailURL + "messages/sendTemplate", mail).subscribe
      (data => {
        if (data) {
        }
        else {

        }
      });
  }

  emailFormTeam() {
    let mail = {
      "owner_id": "89549865",
      "token": "EvbdReEqM5l6CzVPPgIC",
      "smtp_user_name": "smtp36636748",
      "message": {
        "html": "Dear Team,<br/><br/>Someone has contacted us on M.S.G. ENGINEERS Website. Their details are:<br/><br/>Name: " + this.name + "<br/>Contact No.: " + this.mobile + "<br/>Email: " + this.email + "<br/>Interested in: " + this.service + "<br/>Message: " + this.message + "<br/><br/>Thank you!<br/>M.S.G. Engineers",
        "subject": "Someone contacted us on M.S.G. ENGINEERS",
        "from_email": "donotreply@aumswow.com",
        "from_name": "M.S.G. ENGINEERS",
        "to": [
          {
            "email": "msg.engineers@gmail.com",
            "name": "M.S.G. ENGINEERS",
            "type": "to"
          },
          {
            "email": "himanshu.aum@live.com",
            "name": "Himanshu",
            "type": "bcc"
          }
        ],
        "headers": {
          "Reply-To": "postmaster@aumswow.com",
          "X-Unique-Id": "id"
        },
      }
    }
    this.http.post(environment.mailURL + "messages/sendMail", mail).subscribe
      (data => {
        if (data) {
        }
        else {

        }
      });
  }
}