<div class="socialMediaHeader web">
    <div class="containerX" style="display: flex;">
        <div class="socialMediaIcon"><a href="mailto:msg.engineers@gmail.com"><i class="fas fa-at"></i>&nbsp;&nbsp;msg.engineers@gmail.com</a></div>
        <div class="socialMediaIcon"><a href="tel:+919818480648"><i class="fas fa-phone"></i>&nbsp;&nbsp;+91 98 18 480 648</a></div>
    </div>
</div>
<div class="navHeader web">
    <div class="containerX">
        <div class="webmenu">
            <div class="logoBox">
                <div class="brandName">M.S.G. ENGINEERS
                </div>
                <div style="font-size: 0.8rem; color: white;">(An ISO 9001:2015 Company)</div>
            </div>
            <div class="menuBox">
                <div class="navbar-expand">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/about-msg-engineers">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/our-services-cnc-finished-parts">Our Services</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/gallery">Gallery</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/contact-msg-engineers">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="fixed-logo">
        <img style="width: 100%;" src="assets/images/MSG-Engineers-Color-Logo.png" />
    </div>
</div>
<div class="mobilemenu">
    <div class="navHeader logoBox" style="vertical-align: middle;">
        <div class=""><img style="height: 50px;" src="assets/images/MSG-Engineers-Logo.png" /></div>
        <div class="brandName">
            <p>M.S.G. ENGINEERS</p>
            <div style="font-size: 0.8rem; color: white;">(An ISO 9001:2015 Company)</div>
        </div>
        <div class="bars" (click)="toggleMobileNav()" [class.rBars]="mobileNav" style="text-align:center; margin: 10px 0"><i class="fas fa-bars"></i></div>
        <!-- <div class="bars" (click)="toggleMobileNav()" *ngIf="mobileNav" style="text-align:center; margin: 10px 0"><i class="fas fa-bars" style="transform: rotate(90deg);"></i></div> -->
    </div>
    <div class="navBox">
        <div *ngIf="mobileNav" class="navMobile">
            <ul>
                <li routerLink="/">Home</li>
                <li routerLink="/about-msg-engineers">About Us</li>
                <li routerLink="/our-services-cnc-finished-parts">Our Services</li>
                <li routerLink="/gallery">Gallery</li>
                <li routerLink="/contact-msg-engineers">Contact Us</li>
            </ul>
        </div>
    </div>
</div>