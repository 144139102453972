import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-services-vmc-finished-parts',
  templateUrl: './our-services-vmc-finished-parts.component.html',
  styleUrls: ['../our-services/our-services.component.css']
})
export class OurServicesVmcFinishedPartsComponent implements OnInit {

  services = [
    {
      Group: "VMC Finished Parts",
      Images: [
        {ID: 1, Path: "MSG-Engineers-VMC-Finished-Parts-1.jpg"},
        {ID: 2, Path: "MSG-Engineers-VMC-Finished-Parts-2.jpg"},
        {ID: 3, Path: "MSG-Engineers-VMC-Finished-Parts-3.jpg"},
        {ID: 4, Path: "MSG-Engineers-VMC-Finished-Parts-4.jpg"},
        {ID: 5, Path: "MSG-Engineers-VMC-Finished-Parts-5.jpg"},
        {ID: 6, Path: "MSG-Engineers-VMC-Finished-Parts-6.jpg"},
      ]
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
