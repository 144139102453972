import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-services-rotavator-parts',
  templateUrl: './our-services-rotavator-parts.component.html',
  styleUrls: ['../our-services/our-services.component.css']
})
export class OurServicesRotavatorPartsComponent implements OnInit {

  services = [
    {
      Group: "Rotavator Parts",
      Images: [
        {ID: 1, Path: "MSG-Engineers-Rotavator-Parts-1.jpg"},
        {ID: 2, Path: "MSG-Engineers-Rotavator-Parts-2.jpg"},
        {ID: 3, Path: "MSG-Engineers-Rotavator-Parts-3.jpg"},
        {ID: 4, Path: "MSG-Engineers-Rotavator-Parts-4.jpg"},
      ]
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
