import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.css']
})

export class OurServicesComponent implements OnInit {

  services = [
    {
      Group: "CNC Finished Parts", 
      Images: [
        {ID: 1, Path: "MSG-Engineers-CNC-Finished-Parts-1.jpg"},
        {ID: 2, Path: "MSG-Engineers-CNC-Finished-Parts-2.jpg"},
        {ID: 3, Path: "MSG-Engineers-CNC-Finished-Parts-3.jpg"},
        {ID: 4, Path: "MSG-Engineers-CNC-Finished-Parts-4.jpg"},
        {ID: 5, Path: "MSG-Engineers-CNC-Finished-Parts-5.jpg"},
        {ID: 6, Path: "MSG-Engineers-CNC-Finished-Parts-6.jpg"},
        {ID: 7, Path: "MSG-Engineers-CNC-Finished-Parts-7.jpg"},
      ]
    }
  ]

  modal: NgbModalRef;

  constructor(private modalService: NgbModal,) { }

  ngOnInit(): void {
  }

  openmodal(modal) {
    this.modal = this.modalService.open(modal, { keyboard: false, centered: true, size: 'lg' });
  }

}
